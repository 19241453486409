import * as Html from 'BaxterScript/helper/browser/Html';
import { Config } from 'BaxterScript/types/Config';

export const webpackExclude = (config: Config): boolean => !config.app?.scripts?.length;

export const bootstrap = () => {
  const { appId } = globalThis.Baxter.config;
  const { scripts } = globalThis.Baxter.config?.app || { scripts: [] };

  scripts.forEach((script, i) => {
    const attributes = { ...script.attributes };
    let otDataIgnore = false;
    if (attributes['data-ot-ignore'] === 'true') {
      otDataIgnore = true;
      delete attributes['data-ot-ignore'];
    }
    let otClass = '';
    if (attributes.class) {
      otClass = attributes.class;
      delete attributes.class;
    }
    Html.addScriptToHead(`baxter-script${i}-${appId}`, otDataIgnore, otClass, script.path, script.attributes);
  });
};

export default {
  bootstrap,
};
